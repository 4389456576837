import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import ShopifyDistancesDetail from "./ShopifyDistancesDetail";
import { Tooltip, message, Modal as ModalAntd, Button, Space } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import { deleteShopifyDistances } from "../Application/ShopifyDistances.business";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const ShopifyDistancesBoard = ({
  shopifyDistances,
  getShopifyDistances,
  totalCounShopifyDistances,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCounShopifyDistances]);

  const handleDelete = async (id) => {
    try {
      ModalAntd.confirm({
        title: (
          <span
            style={{
              fontFamily: "MontserratRegular",
              fontWeight: 600,
              color: "#1D1D1F",
            }}
          >
            ¿Está seguro de eliminar esta distancia de Shopify?
          </span>
        ),
        icon: <ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />,
        content: (
          <div
            style={{
              fontFamily: "MontserratRegular",
              fontSize: "14px",
              color: "#595959",
            }}
          >
            Esta acción no se puede deshacer. Todos los datos relacionados se
            eliminarán de forma permanente.
          </div>
        ),
        centered: true,
        okText: "Sí, eliminar",
        getContainer: document.getElementById("root"),
        okType: "danger",
        cancelText: "Cancelar",
        style: {
          borderRadius: "12px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
          fontFamily: "MontserratRegular",
        },

        okButtonProps: {
          style: {
            fontFamily: "MontserratRegular",
            backgroundColor: "#d9363e",
            borderColor: "#d9363e",
            color: "white",
            transition: "all 0.3s ease-in-out",
            borderRadius: "8px",
            fontWeight: "500",
          },
          onMouseEnter: (e) => {
            e.target.style.backgroundColor = "#a82222";
            e.target.style.borderColor = "#a82222";
            e.target.style.filter = "brightness(1.2)";
            e.target.style.transform = "scale(1.05)";
            e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
          },
          onMouseLeave: (e) => {
            e.target.style.backgroundColor = "#d9363e";
            e.target.style.borderColor = "#d9363e";
            e.target.style.filter = "brightness(1)";
            e.target.style.transform = "scale(1)";
            e.target.style.boxShadow = "none";
          },
        },

        cancelButtonProps: {
          style: {
            fontFamily: "MontserratRegular",
            backgroundColor: "#f5f5f5",
            borderRadius: "8px",
            transition: "all 0.3s",
            color: "#595959",
          },
          onMouseEnter: (e) => (e.target.style.backgroundColor = "#e6e6e6"),
          onMouseLeave: (e) => (e.target.style.backgroundColor = "#f5f5f5"),
        },
        onOk: () => {
          deleteShopifyDistances(id);
          message.success("Se eliminó la distancia de Shopify correctamente.");
        },
        onCancel: () => message.info("Eliminación cancelada."),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo.");
    }
  };

  const columnsShopifyDistances = [
    {
      title: "Municipio",
      dataIndex: "municipality",
      key: "municipality",
    },
    {
      title: "Distancia",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space size="middle">
          <ShopifyDistancesDetail record={record} refreshData={fetchData} />
          <Tooltip title="Eliminar una distancia de Shopify ">
            <Button
              type="text"
              icon={
                <DeleteOutlined
                  onClick={() => {
                    handleDelete(record.id);
                  }}
                  className="icon-delete"
                />
              }
              style={{ transition: "all 0.3s" }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getShopifyDistances(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCounShopifyDistances,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={shopifyDistances}
        columns={columnsShopifyDistances}
        loading={loading}
      />
    </div>
  );
};

export default ShopifyDistancesBoard;
