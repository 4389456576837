import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import TransportTypesBoard from "./TransportTypesBoard";
import { getTransportTypes } from "../Application/TransportTypes.business";
import {
  getTransportTypesSelector,
  getTransportTypesCountSelector,
} from "../Infrastructure/TransportTypes.reducer";
import "./TransportTypes.css";
import { Card } from "antd";

const TransportTypes = () => {
  const transportTypes = useSelector(getTransportTypesSelector);
  const totalCountTransportTypes = useSelector(getTransportTypesCountSelector);

  useEffect(() => {
    getTransportTypes();
  }, []);
  return (
    <section className="transport-types-container">
      <Card className="transport-types-card" bordered={false}>
        <header className="transport-types-header">
          <Title>Tipos de Transporte</Title>
        </header>
        <main>
          <TransportTypesBoard
            transportTypes={transportTypes}
            getTransportTypes={getTransportTypes}
            totalCountTransportTypes={totalCountTransportTypes}
          />
        </main>
      </Card>
    </section>
  );
};

export default TransportTypes;
