import React from "react";
import "./Utils.css";

export function Title({ children }) {
  return <span className="title">{children}</span>;
}

export function Subtitle({ children }) {
  return <span className="subtitle">{children}</span>;
}

export function TextBody({ children }) {
  return <p className="textbody">{children}</p>;
}

export function Tag({ children }) {
  return <span className="tag">{children}</span>;
}
