class CostCenters {
 
    constructor({
      id,
      _id,
      code,
      area,
      project,
    }) {
      this.id = id || _id;
      this.code = code;
      this.area = area;
      this.project = project;
    }
  
  }
  
  export default CostCenters;