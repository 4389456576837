import admins from "../Administrator/Infrastructure/Administrators.reducer";
import areas from "../Areas/Infrastructure/Areas.reducer";
import bankAccounts from "../CompaniesBankDetails/Infrastructure/CompaniesBankDetails.reducer";
import carbonFlux from "../CarbonFlux/Infrastructure/CarbonFlux.reducer";
import categories from "../Categories/Infrastructure/Categories.reducer";
import categoriesEmission from "../EmissionCategory/Infrastructure/EmissionCategory.reducer";
import collaborators from "../Collaborators/Infrastructure/Collaborators.reducer";
import companies from "../Companies/Infrastructure/Companies.reducer";
import costCenters from "../CostCenters/Infrastructure/CostCenters.reducer";
import dataUsers from "../DataUser/Infrastructure/DataUser.reducer";
import documents from "../DocumentUpload/Infrastructure/DocumentUpload.reducer";
import equivalentCodes from "../EquivalentCodes/Infrastructure/EquivalentCodes.reducer";
import fees from "../Commissions/Infrastructure/Commissions.reducer";
import keys from "../Keys/Infrastructure/Keys.reducer";
import logs from "../Logs/Infrastructure/Logs.reducer";
import nfts from "../NFTS/Infrastructure/Nfts.reducer";
import offers from "../Offers/Infrastructure/Offers.reducer";
import offsets from "../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import permissions from "../Permissions/Infrastructure/Permissions.reducer";
import project from "../Owners/Infrastructure/Owners.reducer";
import projects from "../Projects/Infrastructure/Projects.reducer";
import reductions from "../Saving/Infrastructure/Saving.reducer";
import repositoryProjects from "../RepositoryProjects/Infrastructure/RepositoryProjects.reducer";
import scriptOffsets from "../ScriptOffsets/Infrastructure/ScriptOffsets.reducer";
import shopifyDistances from "../ShopifyDistances/Infrastructure/ShopifyDistances.reducer";
import stationData from "../StationData/Infrastructure/StationData.reducer";
import stats from "../Dashboard/Infrastructure/Dashboard.reducer";
import subcategories from "../Subcategories/Infrastructure/Subcategories.reducer";
import suppliers from "../Suppliers/Infrastructure/Suppliers.reducer";
import transactions from "../Transactions/Infrastructure/Transactions.reducer";
import transportTypes from "../TransportTypes/Infrastructure/TransportTypes.reducer";
import users from "../Users/Infrastructure/Users.reducer";
import years from "../YearsUser/Infrastructure/YearsUser.reducer";

const reducer = {
  admins,
  areas,
  bankAccounts,
  carbonFlux,
  categories,
  categoriesEmission,
  collaborators,
  companies,
  costCenters,
  dataUsers,
  documents,
  equivalentCodes,
  fees,
  keys,
  logs,
  nfts,
  offers,
  offsets,
  permissions,
  project,
  projects,
  reductions,
  repositoryProjects,
  scriptOffsets,
  shopifyDistances,
  stationData,
  stats,
  subcategories,
  suppliers,
  transactions,
  transportTypes,
  users,
  years,
};

export default reducer;
