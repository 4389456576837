import store from "../../Redux/store";
import {  setTransportTypes,
  countTransportTypes, } from "./TransportTypes.reducer";

export function saveListTransportTypes(records) {
  store.dispatch(setTransportTypes(records));
}

export function saveCounTransportTypes(count) {
  store.dispatch(countTransportTypes(count));
}
