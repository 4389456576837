import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import EquivalentCodesBoard from "./EquivalentCodesBoard";
import { getEquivalentCodes } from "../Application/EquivalentCodes.business";
import {
  getEquivalentCodesSelector,
  getEquivalentCodesCountSelector,
} from "../Infrastructure/EquivalentCodes.reducer";
import "./EquivalentCodes.css";
import { Card } from "antd";

const EquivalentCodes = () => {
  const equivalentCodes = useSelector(getEquivalentCodesSelector);
  const totalCountEquivalentCodes = useSelector(
    getEquivalentCodesCountSelector
  );

  useEffect(() => {
    getEquivalentCodes();
  }, []);
  return (
    <section className="equivalent-codes-container">
      <Card className="equivalent-codes-card" bordered={false}>
        <header className="equivalent-codes-header">
          <Title> Códigos equivalentes</Title>
        </header>
        <main>
          <EquivalentCodesBoard
            equivalentCodes={equivalentCodes}
            getEquivalentCodes={getEquivalentCodes}
            totalCountEquivalentCodes={totalCountEquivalentCodes}
          />
        </main>
      </Card>
    </section>
  );
};

export default EquivalentCodes;
