import { getShopifyDistancesRepository ,updateShopifyDistancesRepository,deleteShopifyDistancesRepository} from "../Infrastructure/ShopifyDistances.repository";

export async function getShopifyDistancesLogic(params) {
  return getShopifyDistancesRepository(params);
}
export async function updateShopifyDistancesLogic(id, params) {
  return updateShopifyDistancesRepository(id, params);
}
export async function deleteShopifyDistancesLogic(id) {
  return deleteShopifyDistancesRepository(id);
}
