import React, { useState } from "react";
import { Input, message } from "antd";
import { getShopifyDistances } from "../Application/ShopifyDistances.business";
import { postShopifyDistances } from "../Infrastructure/ShopifyDistances.service";
import { PlusCircleOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";

const NewShopifyDistances = () => {
  const [values, setValues] = useState({
    municipality: "",
    distance: "",
  });
  const [loading, setLoading] = useState(false);

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    const { municipality, distance } = values;

    if (!municipality.trim() || !distance.trim() || isNaN(distance)) {
      message.error(
        "Por favor, ingresa una municipalidad y una distancia válida"
      );
      setLoading(false);
      return;
    }

    try {
      await postShopifyDistances({ municipality, distance: Number(distance) });
      getShopifyDistances();
      message.success("Se agregó una nueva distancia");

      setValues({ municipality: "", distance: "" });
    } catch (error) {
      console.error("Error detectado:", error);
      message.error("Se produjo un error, vuelve a intentarlo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        children={
          <div>
            <form
              className="horizontal-form-shopifyDistances"
              onSubmit={handleSubmit}
            >
              <div className="form-group-shopifyDistances">
                <label
                  htmlFor="municipalidad"
                  className="form-label-shopifyDistances"
                >
                  Municipalidad:
                </label>
                <Input
                  placeholder="Municipalidad"
                  id="municipalidad"
                  value={values.municipality}
                  onChange={onHandleChange("municipality")}
                  className="input-style-shopifyDistances "
                />
              </div>
              <div className="form-group-shopifyDistances">
                <label
                  htmlFor="distancia"
                  className="form-label-shopifyDistances"
                >
                  Distancia:
                </label>
                <Input
                  placeholder="Distancia"
                  id="distancia"
                  value={values.distance}
                  onChange={onHandleChange("distance")}
                  type="number"
                  className="input-style-shopifyDistances "
                />
              </div>
            </form>
          </div>
        }
        icon={<PlusCircleOutlined className="icon-style" />}
        className="icon-style"
        title="Agregar Distancia de Shopify"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default NewShopifyDistances;
