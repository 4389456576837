import store from "../../Redux/store";
import {  setEquivalentCodes,
  countEquivalentCodes, } from "./EquivalentCodes.reducer";

export function saveListEquivalentCodes(records) {
  store.dispatch(setEquivalentCodes(records));
}

export function saveCountEquivalentCodes(count) {
  store.dispatch(countEquivalentCodes(count));
}
