import Response from "../../Data/Domain/Response";
import ShopifyDistances from "../Domain/ShopifyDistances";
import { getShopifyDistances ,putShopifyDistances,deleteShopifyDistances} from "./ShopifyDistances.service";

export async function getShopifyDistancesRepository(params) {
  const data = await getShopifyDistances(params);
  return new Response(data, ShopifyDistances).getMultiple();
}
export async function updateShopifyDistancesRepository(id, params) {
  const data = await putShopifyDistances(id, params);
  return new Response(data, ShopifyDistances).onlyStatus();
}
export async function deleteShopifyDistancesRepository(id) {
  const data = await deleteShopifyDistances(id);
  return new Response(data).onlyStatus();
}
