import { saveListShopifyDistances, saveCountShopifyDistances ,deleteSelectedShopifyDistances} from "../Infrastructure/ShopifyDistances.store";
import { 
  getShopifyDistancesLogic,updateShopifyDistancesLogic,deleteShopifyDistancesLogic } from "./ShopifyDistances.logic";

export async function getShopifyDistances(params) {
  const { count, resources: shopifyDistances } = await getShopifyDistancesLogic(params);
  saveListShopifyDistances(shopifyDistances);
  saveCountShopifyDistances(count);
}

export async function updateShopifyDistances(id, params) {
  await updateShopifyDistancesLogic(id, params);
}

export async function deleteShopifyDistances(id) {
  await deleteShopifyDistancesLogic(id);
  deleteSelectedShopifyDistances(id);
}
