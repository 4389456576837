class TransportTypes {
 
    constructor({
      id,
      _id,
      description,
      classification,
      transportType,
      FE_year,
      FE,
      units
    }) {
      this.id = id || _id;
      this.description = description;
      this.classification = classification;
      this.transportType = transportType;
      this.FE_year = FE_year;
      this.FE=FE;
      this.units = units;
    }
  
  }
  
  export default TransportTypes;