import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "shopifyDistances",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setShopifyDistances: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
   
    countShopifyDistances: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    deleteShopifyDistances: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },

  },
});

export const {
  setShopifyDistances,
  countShopifyDistances,
  deleteShopifyDistances
} = slice.actions;

export default slice.reducer;

export const getShopifyDistancesSelector = (store) => store.shopifyDistances.list;
export const getShopifyDistancesCountSelector = (store) => store.shopifyDistances.count;
export const deleteShopifyDistancesSelector = (store) => store.shopifyDistances.id;
