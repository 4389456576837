class EquivalentCodes {
 
    constructor({
      id,
      _id,
      code,
      equivalence,
      year
    }) {
      this.id = id || _id;
      this.code = code;
      this.equivalence = equivalence;
      this.year = year;

    
    }
  
  }
  
  export default EquivalentCodes;