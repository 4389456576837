import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import { message } from "antd";
import Formatter from "../../Data/Domain/Formatter";

const EquivalentCodesBoard = ({
  equivalentCodes,
  getEquivalentCodes,
  totalCountEquivalentCodes,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});
  const columnsEquivalentCodes = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Equivalencia",
      dataIndex: "equivalence",
      key: "equivalence",
    },
    {
      title: "Año",
      dataIndex: "year",
      key: "year",
    },
  ];

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);
      await getEquivalentCodes(params);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCountEquivalentCodes,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={equivalentCodes}
        columns={columnsEquivalentCodes}
        loading={loading}
      />
    </>
  );
};

export default EquivalentCodesBoard;
