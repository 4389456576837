import React, { useState } from "react";
import ModalInterface from "antd/lib/modal/Modal";
import { Button } from "antd";
import "./Modal.css";
import { PlusCircleOutlined } from "@ant-design/icons";

const Modal = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const handleOk = async () => {
        if (props.handleSubmit) {
            await props.handleSubmit();
        }
        setIsModalVisible(false);
        if (props.onOk) {
            props.onOk();
        }
    };

    const handleSubmitAndClose = async (e) => {
        if (props.handleSubmit) {
            try {
                (await props.handleSubmit) && props.handleSubmit(e);
                setIsModalVisible(false);
            } catch (error) {
                console.error(" Error :", error);
            }
        }
    };

    const textFont = {
        fontFamily: "MontserratMedium",
    };

    const cancelButtonProps = props.cancelButtonProps
        ? props.cancelButtonProps
        : props.footer;
    const okButtonProps = props.okButtonProps
        ? props.okButtonProps
        : props.footer;

    return (
        <div>
            <Button
                type="link"
                icon={props.icon}
                style={props.style}
                onClick={showModal}
            />
            <ModalInterface
                getContainer={false}
                title={props.title}
                style={textFont}
                visible={isModalVisible ? isModalVisible : props.visible}
                onCancel={handleCancel}
                width={props.width}
                cancelButtonProps={cancelButtonProps}
                okButtonProps={okButtonProps}
                centered
                onOk={handleOk}
                okText={props.okText}
                footer={
                    props.footer || [
                        <Button
                            className="button-modal"
                            key="submit"
                            type="primary"
                            htmlType="button"
                            onClick={handleSubmitAndClose}
                            icon={<PlusCircleOutlined />}
                        >
                            {props.text}
                        </Button>,
                    ]
                }
                onClose={props.onClose}
            >
                {props.children}
            </ModalInterface>
        </div>
    );
};

export default Modal;
