import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateShopifyDistances } from "../Application/ShopifyDistances.business";
import Modal from "../../Modal/Modal";
import { Input, message, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./ShopifyDistances.css";

const ShopifyDistancesDetail = ({ record, refreshData }) => {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const onHandleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      municipality: values.municipality,
      distance: values.distance,
    };

    try {
      await updateShopifyDistances(record.id, data);
      message.success("ShopifyDistances actualizado con éxito");
      refreshData();
      setLoading(false);
    } catch (error) {
      message.error("Error al actualizar ShopifyDistances");
      setLoading(false);
    }
    dispatch(updateShopifyDistances(record.id, data));
  };

  return (
    <div>
      <Modal
        children={
          <form
            className="horizontal-form-shopifyDistances"
            onSubmit={handleUpdate}
          >
            {record && (
              <>
                <div className="form-group-shopifyDistances">
                  <label
                    htmlFor="municipalidad"
                    className="form-label-shopifyDistances"
                  >
                    Municipalidad:
                  </label>
                  <Input
                    placeholder="Municipalidad"
                    id="municipalidad"
                    name="municipality"
                    value={values?.municipality || ""}
                    onChange={onHandleChange}
                    className="input-style-shopifyDistances"
                  />
                </div>
                <div className="form-group-shopifyDistances">
                  <label
                    htmlFor="distancia"
                    className="form-label-shopifyDistances"
                  >
                    Distancia:
                  </label>
                  <Input
                    placeholder="Distancia"
                    id="distancia"
                    name="distance"
                    value={values?.distance || ""}
                    onChange={onHandleChange}
                    type="number"
                    className="input-style-shopifyDistances"
                  />
                </div>
              </>
            )}
          </form>
        }
        icon={
          <Tooltip title="Editar una distancia de Shopify">
            <EditOutlined className="icon-edit" />
          </Tooltip>
        }
        title="Editar Distancia de Shopify"
        cancelButtonProps={{ style: { display: "none" } }}
        text="Editar"
        handleSubmit={handleUpdate}
      />
    </div>
  );
};

export default ShopifyDistancesDetail;
