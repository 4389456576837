import store from "../../Redux/store";
import { deleteShopifyDistances, setShopifyDistances,countShopifyDistances, } from "./ShopifyDistances.reducer";

export function saveListShopifyDistances(records) {
  store.dispatch(setShopifyDistances(records));
}

export function saveCountShopifyDistances(count) {
  store.dispatch(countShopifyDistances(count));
}
export function deleteSelectedShopifyDistances(id) {
  store.dispatch(deleteShopifyDistances(id));
}
