import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "transportTypes",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setTransportTypes: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
   
    countTransportTypes: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },

  },
});

export const {
  setTransportTypes,
  countTransportTypes,
} = slice.actions;

export default slice.reducer;

export const getTransportTypesSelector = (store) => store.transportTypes.list;
export const getTransportTypesCountSelector = (store) => store.transportTypes.count;
