import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import CostCentersBoard from "./CostCentersBoard";
import { getCostCenters } from "../Application/CostCenters.business";
import {
  getCostCentersSelector,
  getCostCentersCountSelector,
} from "../Infrastructure/CostCenters.reducer";
import "./CostCenters.css";
import { Card } from "antd";

const CostCenters = () => {
  const costCenters = useSelector(getCostCentersSelector);
  const totalCountCostCenters = useSelector(getCostCentersCountSelector);

  useEffect(() => {
    getCostCenters();
  }, []);
  return (
    <section className="cost-centers-container">
      <Card className="cost-centers-card" bordered={false}>
        <header className="cost-centers-header">
          <Title> Centros de Costos</Title>
        </header>
        <main>
          <CostCentersBoard
            costCenters={costCenters}
            getCostCenters={getCostCenters}
            totalCountCostCenters={totalCountCostCenters}
          />
        </main>
      </Card>
    </section>
  );
};

export default CostCenters;
