import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageHeader, Descriptions, Tag } from "antd";

import "antd/dist/antd.min.css";
import { getSelectedProjectSelector } from "../Infrastructure/Projects.reducer";
import { getProject } from "../Application/Projects.business";

const tagText = {
  fontFamily: "MontserratMedium",
  fontSize: "12px",
  fontWeight: 500,
};

export default function ProjectDetails() {
  const { id } = useParams();

  const project = useSelector(getSelectedProjectSelector);

  useEffect(() => {
    id && getProject(id);
  }, [id]);

  const navigate = useNavigate();

  const cancel = () => {
    navigate("/projects");
  };

  const [key, setKey] = useState(null);
  useEffect(() => {
    setKey(key);
  }, [key]);

  return project ? (
    <div>
      <PageHeader
        onBack={cancel}
        className="site-page-header"
        tags={
          <Tag color="green" style={tagText}>
            {project.real_name}
          </Tag>
        }
        extra={[]}
        avatar={{
          src: project.url,
        }}
      >
        <Descriptions title="" layout="vertical" bordered>
          <Descriptions.Item label="Nombre" span={2}>
            {project.project_name}
          </Descriptions.Item>
          <Descriptions.Item label="Descripción" span={1}>
            {project.description}
          </Descriptions.Item>

          <Descriptions.Item label="País">
            {project.country || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Region">
            {project.region || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Provincia">
            {project.province || ""}
          </Descriptions.Item>

          <Descriptions.Item label="Precio">
            {project.ton_price || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {project.statusValue || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Bonos disponibles">
            {project.availableBonds || ""}
          </Descriptions.Item>
        </Descriptions>
        ,
      </PageHeader>
      ,
    </div>
  ) : (
    <div />
  );
}
