import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getShopifyDistances(params) {
  return await new API(
    URL.setParams(URL.buildUrl("shopifyDistances"), params)
  ).get();
}

export async function postShopifyDistances({ municipality, distance }) {
  return await new API(URL.buildUrl("shopifyDistances")).post({
    municipality,
    distance,
  });
}

export async function putShopifyDistances(id, data) {
  const url = URL.setId(URL.buildUrl("shopifyDistances"), id);
  return await new API(url).put(data);
}
export async function deleteShopifyDistances(id) {
  return await new API(URL.setId(URL.buildUrl("shopifyDistances"), id)).delete();
}
